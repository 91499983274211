
//    Font Family

$primary-font	: 'Montserrat', sans-serif;

// --------------------------- Color variations ----------------------

$primary-color	: #005596;  
// $primary-color	: #ff7500;  //#

$primary-color2	: #6CACE4;

//$title-color	: #1e283c;
//$title-color	: #005596;  
$text-color		: #50668f;
$title-color	: $text-color; 
$aquablue-color : #e6f7fc;

$white			: #fff;
$offwhite		: #f2f5fa;
$lightgrey   	: #e1e4e7;
$black			: #222;
