/*--------------------------------------------------------------
# Price
--------------------------------------------------------------*/

.version1 {
    .price-section {
        @extend .aquablue-bg;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.version1-1 {
    .price-section {
        @extend .aquablue-bg;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.version2 {
    .price-section {
        background: url(../img/price-bg.jpg) no-repeat bottom center/cover;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price2 {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.version3 {
    .price-section {
        @extend .aquablue-bg;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.version4 {
    .price-section {
        background: url(../img/price-bg.jpg) no-repeat bottom center/cover;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price2 {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.version8 {
    .price-section {
        @extend .aquablue-bg;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.version9 {
    .price-section {
        background: url(../img/price-bg.jpg) no-repeat bottom center/cover;
        @media(max-width: 991px) {
            .col-lg-4:last-child {
                .single-price2 {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.price-section {
    .section-title-wrap {
        .title-img {
            &:before {
                background: $offwhite;
            }
        }
    }
}

.single-price {
    background: #fff;
    padding: 48px;
    border: 2px solid #fff;
    @include transition();
    .price-top {
        @include transition();
        h4 {
            color: $title-color;
            font-weight: 500;
            margin-bottom: 8px;
        }
        p {
            color: $text-color;
            margin-bottom: 0px;
        }
        .price {
            margin-top: 45px;
            p {
                color: $text-color;
                margin-bottom: 0px;
                span {
                    color: #1e283c;
                    font-size: 48px;
                    font-weight: 700
                }
            }
        }
    }
    .price-bottom {
        padding-top: 45px;
        text-align: center;
        .packlist {
            margin-bottom: 30px;
            li {
                border-bottom: 1px solid $aquablue-color;
                margin-bottom: 10px;
                padding-bottom: 10px;
                color: $text-color;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    &:hover {
        border: 2px solid $primary-color;
    }
    @media(max-width:991px) {
        margin-bottom: 50px;
    }
}

.single-price2 {
    background: $white;
    padding: 20px;
    text-align: center;
    @include transition();
    @media(max-width: 991px) {
        margin-bottom:30px;
    }
    .price-top {
        padding: 30px;
        @include transition();
        h1,
        h4,
        p {
            @include transition();
        }
        .overlay-bg {
            @extend .d3-gradient-bg;
            opacity: 0;
            @include transition();
        }
    }
    .price-bottom {
        padding: 30px;
        margin-top: 20px;
    }
    .pack-list {
        li {
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            padding-bottom: 20px;
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
    &:hover {
        .price-top {
            .overlay-bg {
                opacity: 1;
            }
            h1,
            h4,
            p {
                color: $white;
            }
        }
    }
}

.single-price4 {
    background: $white;
    padding: 20px;
    text-align: center;
    @include transition();
    @media(max-width: 991px) {
        margin-bottom:30px;
    }
    .price-top {
        padding: 30px;
        @include transition();
        h1,
        h4,
        p {
            @include transition();
        }
        .overlay-bg {
            @extend .d4-gradient-bg;
            opacity: 0;
            @include transition();
        }
    }
    .price-bottom {
        padding: 30px;
        margin-top: 20px;
    }
    .pack-list {
        li {
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            padding-bottom: 20px;
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
    &:hover {
        box-shadow: 0px 10px 30px 0px rgba(135, 170, 218, 0.3);
        .price-top {
            .overlay-bg {
                opacity: 1;
            }
            h1,
            h4,
            p {
                color: $white;
            }
        }
    }
}