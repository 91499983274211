blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-weight: 600;
    border-left: 5px solid #eee;
    border-left-color: rgb(238, 238, 238);
  }

  // from https://www.chunkhang.com/blog/responsive-youtube-video-with-jekyll
  .youtube-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: 50px;    // not required for responsive behaviour - just for spacing of videos
  }
  .youtube-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .page-top-section h1 {
    font-weight: 500;
  }

  a {
    // Override default in _reboot.scss from bootstrap
    color: $primary-color; // #005596;
    font-weight: bold;
  }
  
  // Don't style text in a card or other block, where the whole block is a hyperlink
  .single-card a, .single-feature a, .single-points a{
    color:inherit;
    font-weight: inherit;
  }

 
 // Basic heading sizes - copied from /theme/_reset.scss
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
     font-weight: bold;
 }

 h1,
.h1 {
    font-size: 36px;
}

h2,
.h2 {
    font-size: 24px;
}

h3,
.h3 {
    font-size: 24px;
}

h4,
.h4 {
    font-size: 20px;
}

h5,
.h5 {
    font-size: 16px;
}

h6,
.h6 {
    font-size: 14px;
    color: $title-color;
}

h1 {
    @media(max-width: 320px) {
        font-size: 28px;
    }
}