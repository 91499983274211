body.posts {
    header + section.page-top-section {
        padding-top: 100px;
        background-color: $primary-color2; /* For browsers that do not support gradients */
        background-image: linear-gradient(285deg, $primary-color, $primary-color2);
    }
    .section-title-wrap .title-img {padding-top: 40px;}
    img.post-image {width: 400px; float: right; margin-left: 50px; padding-bottom: 30px;}
    .footer-area {margin-top: 100px;}
    .blog-post {
        ul {
            padding-bottom: 20px;
            list-style: circle; 
            list-style-position: outside;
            padding-left: 20px;
            li {
                ul {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 1em;
                }
                ul > li:before {
                    display: inline-block;
                    content: "-";
                    width: 1em;
                    margin-left: -1em;
                }    
            }
        }
    }
}