div.single-implementation {
    padding: 30px;
    div.icon {
        font-size: 60px;
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    div.desc p {clear: both;}

}    