
/*--------------------------------------------------------------
# Carusel
--------------------------------------------------------------*/

.marketing-section{
	.marketing-left{
		padding-left: 20%;
		@media(max-width:1366px){
			padding-left:4%;
		}
		@media(max-width:991px){
			padding-left:15px;
		}
		h1 {
			font-size: 48px;
			font-weight: 500;
		}
		p {
			margin: 25px 0 42px;
			max-width: 500px;
		}
	}

	.marketing-right{
		.active-dash-carusel{
			max-width:850px; 
			@media(max-width:991px){
				margin-right:auto;
				margin-left:auto;
				display: block;
			}
		}
		
	}

}

/* This was copied here from _hero.scss, as without it, there is no styling of the carousel dots (it is otherwise necessary to include the owl 
carousel theme css. We use the carousel for displaying  client testimonials. If necessary, we could create a specific scss file for that */
.owl-controls {
	.owl-dots {
	  position: absolute;
	  // bottom: 28px; makes them overlay the bottom of some testimonials
	  left: 46%;
	  .owl-dot {
		background: #6e737f;
		width: 8px;
		height: 8px;
		display: inline-block;
		margin-right: 4px;
		margin-left: 4px;
		border-radius: 8px;
	  }
	  .owl-dot.active {
		background: $primary-color;
		width: 8px; // was 42px;
		height: 8px;
		margin-right: 2px;
		border-radius: 11px;
	  }
	}
  }

