/*--------------------------------------------------------------
# Menu
--------------------------------------------------------------*/

.pages {
    #header {
        background: $white;
    }
}
.version-blog {
    #header7 {
        background: $white;
    }
}


// fixed 
@media (min-width: 1025px) and (max-width: 1100px) { 

    #header {
        .genric-btn{
            display: none;
        }
    }
 }

#header {
    padding: 14px 0px;
    padding-left: 7%;
    padding-right: 7%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
    .genric-btn {
        &:hover {
            color: $white!important;
        }
    }
}

#header.header-scrolled {
    transition: all 0.5s;
    background: $white;
    box-shadow: -21.213px 21.213px 30px 0px rgba(158, 158, 158, 0.3);
    #logo {
        .logo-1 {
            display: none;
        }
        .logo-2 {
            display: inline-block;
        }
    }
    .nav-menu>a {
        color: $black;
    }
}

#header #logo h1 {
    font-size: 34px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 3px;
}

#header #logo h1 a,
#header #logo h1 a:hover {
    padding-left: 10px;
    border-left: 4px solid $primary-color;
}

#header {
    #logo {
        .logo-1 {
            padding: 0;
            margin: 0;
            width: 80%;
            display: inline-block;
        }
        .logo-2 {
            width: 80%;
            display: none;
        }
    }
}

@media (max-width: 768px) {
    #header #logo h1 {
        font-size: 28px;
    }
    #header #logo img {
        //max-height: 40px;
        // TDCH
        max-height: 60px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */

.nav-menu,
.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
    border-top: 2px solid $primary-color;
}

.nav-menu li {
    position: relative;
    white-space: nowrap;
}

.nav-menu>li {
    float: left;
}

.nav-menu li:hover>ul,
.nav-menu li.sfHover>ul {
    display: block;
}

.nav-menu ul ul {
    top: 0;
    left: -115%;
}

.nav-menu ul li {
    min-width: 180px;
    border-bottom: 1px solid #edf6ff;
    &:last-child {
        border-bottom: none;
    }
}

/* Nav Menu Arrows */

.sf-arrows .sf-with-ul {
    padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
    content: "\e874";
    position: absolute;
    right: 10px;
    font-family: 'Linearicons-Free';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
}

.sf-arrows ul .sf-with-ul:after {
    content: "\e876";
}

/* Nav Meu Container */

#nav-menu-container {
    margin: 0;
}

@media (max-width: 768px) {
    #nav-menu-container {
        display: none;
    }
}

/* Nav Meu Styling */

.nav-menu a {
    padding: 0 8px 0px 8px;
    text-decoration: none;
    color: $title-color;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
}

.nav-menu .menu-active {
    a {
        color: $primary-color !important;
    }
}

.nav-menu li>.menu-active {
    color: $primary-color !important;
}

.parent-active>.sf-with-ul {
    color: $primary-color;
}

.parent-active>ul .menu-active {
    color: $primary-color !important;
}

#header.header-scrolled {
    .nav-menu>li .menu-active {
        color: $primary-color !important;
    }
    .parent-active>a {
        color: $primary-color;
    }
    .nav-menu>li a:hover {
        color: $primary-color !important;
    }
}

#mobile-nav .nav-menu>li .menu-active {
    color: $primary-color !important;
}

.nav-menu>li {
    margin-left: 30px;
}

.nav-menu ul {
    margin: 20px 0 0 0;
    padding: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    background: #fff;
}

.nav-menu ul li {
    transition: 0.3s;
}

.nav-menu ul li a {
    padding: 5px 10px;
    color: #333;
    transition: 0.3s;
    display: block;
    font-size: 12px;
}

.nav-menu ul li:hover a {
    color: $primary-color;
}

.nav-menu ul ul {
    margin-top: 0;
    margin-right: 10px;
}

/* Mobile Nav Toggle */

#mobile-nav-toggle {
    position: fixed;
    right: 15px;
    z-index: 999;
    top: 18px;
    border: 0;
    background: none;
    font-size: 24px;
    display: none;
    @include transition();
    outline: none;
    cursor: pointer;
}

#mobile-nav-toggle i {
    color: $black;
    font-weight: 900;
}

@media (max-width: 991px) {
    #mobile-nav-toggle {
        display: inline;
    }
    #nav-menu-container {
        display: none;
    }
}

/* Mobile Nav Styling */

#mobile-nav {
    position: fixed;
    top: 0;
    padding-top: 18px;
    bottom: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.8);
    left: -260px;
    width: 260px;
    overflow-y: auto;
    transition: 0.4s;
}

#mobile-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#mobile-nav ul li {
    position: relative;
}

#mobile-nav ul li a {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    overflow: hidden;
    padding: 10px 22px 10px 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: block;
    outline: none;
    font-weight: 600;
}

#mobile-nav ul li a:hover {
    color: #fff;
}

#mobile-nav ul li li {
    padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
    position: absolute;
    right: 0;
    z-index: 99;
    padding: 15px;
    cursor: pointer;
    color: #fff;
}

#header.header-scrolled .menu-has-children {
    a:hover {
        color: $primary-color;
    }
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
    color: $primary-color;
}

#mobile-nav ul .menu-has-children li a {
    text-transform: none;
}

#mobile-nav ul .menu-item-active {
    color: $primary-color;
}

#mobile-body-overly {
    width: 100%;
    height: 100%;
    z-index: 997;
    top: 0;
    right: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    display: none;
}

/* Mobile Nav body classes */

body.mobile-nav-active {
    overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
    left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
    color: #fff;
}